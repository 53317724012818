import React from 'react';

const Textures = ({part, finishes, finish, setFinish}) => (
  <div className="textures-container">
    <h6 className="field-title">Textures</h6>
    {
      finishes.map((option, index) => (
        <div className="form-check" key={index}>
          <label>
            <input
              type="radio"
              className="form-check-input"
              onChange={() => setFinish(part.id, option.id)}
              checked={option.id === part.finish}
            />
            {option.name}
          </label>
        </div>
      ))
    }
  </div>
)

export default Textures;
