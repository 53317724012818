import React, { useState, useCallback } from 'react'
import { getQuantityMultiplier } from '../../../../utils/pricing'
import debounce from 'lodash/debounce'
import accounting from 'accounting'

const breakpoints = [
  100, 250, 500, 750
]

const PartPricing = ({
  part,
  onQuantityChange,
  onProdQuantityChange,
  setNotification,
  active
}) => {
  const [quantity, setQuantity] = useState(part.quantity)
  const [prodQuantity, setProdQuantity] = useState(part.prodQuantity)

  const handleQuantityChange = (e) => {
    const { value: newValue } = e.target;
    setQuantity(newValue);

    if(newValue !== '') {
      saveQuantityState(newValue);
    }
  }

  const handleProdQuantityChange = (e) => {
    const { value: newValue } = e.target;

    if(newValue !== '' && newValue > 1000) {
      setNotification(part.id, 'Please contact us at info@betajet.com for orders over 1000')
      setProdQuantity(1000);
      saveProdQuantityState(1000);
    } else if(newValue !== '') {
      setProdQuantity(newValue);
      saveProdQuantityState(newValue)
    }
  }

  const renderBreakpointPrice = (quantity) => {
    const multiplier = getQuantityMultiplier(quantity)
    return accounting.formatMoney(part.price * multiplier);
  }

  const saveQuantityState = useCallback(
    debounce(value => {
      onQuantityChange(part.id, value);
    }, 250),
    []
  )

  const saveProdQuantityState = useCallback(
    debounce(value => {
      onProdQuantityChange(part.id, value);
    }, 250),
    []
  )

  return (
    <div className="betajet-part-pricing">
      <div className="flex-horizontal">
        <div >
          <h6 className="field-title">
            Sample Part Qty
          </h6>
          <input
            type="number"
            min="1"
            value={quantity}
            onChange={handleQuantityChange}
            className="form-control form-control--small"
            disabled={!active || part.materialId === null || part.quantity > 1000}
          />
        </div>
        <div >
          <h6 className="field-title">
            Production Part Qty
          </h6>
          <input
            type="number"
            min="0"
            value={prodQuantity}
            onChange={handleProdQuantityChange}
            className="form-control form-control--small"
            disabled={!active || part.materialId === null}
          />
        </div>
      </div>

      <div className="mt-4" >
        <table className="table table-sm table-borderless" >
          <tbody>
            <tr>
              <th scope="row">Mold</th>
              <td>{accounting.formatMoney(part.moldPrice)}</td>
            </tr>
            <tr>
              <th scope="row">Sample Parts</th>
              <td>{accounting.formatMoney(part.price * part.quantity)}</td>
            </tr>
            <tr>
              <th scope="row">Production Parts</th>
              <td>{accounting.formatMoney(part.productionPartPrice)}</td>
            </tr>
            <tr className="betajet-part-pricing__price">
              <th scope="row">Total</th>
              <td>{accounting.formatMoney(part.totalPrice)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      { active && (

        <div>
          <hr />
          <h6 className="field-title">Production Quantity</h6>

          <table className="table table-sm table-borderless">
            <thead >
              <tr>
                <th scope="col" className="pr-4">Qty</th>
                <th scope="col">Price (ea.)</th>
              </tr>
            </thead>
            <tbody>
              {
                breakpoints.map((breakpoint, index) => (
                  <tr key={index}>
                    <th scope="row" className="pr-4">{breakpoint}</th>
                    <td> { renderBreakpointPrice(breakpoint) } </td>
                  </tr>
                ))
              }
              <tr>
                <th scope="row" className="pr-4" >1,000</th>
                <td>Manual RFQ</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default PartPricing
