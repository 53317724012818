import React from 'react'

const Materials = ({
  part,
  materials,
  material,
  setMaterialId
}) => (
  <div className="materials-view">
    <div style={{marginLeft: '2em'}}>
      <h6 className="field-title">Materials</h6>

      <div className="columns columns--c3">
        {
          materials.valueSeq().map(material => (
            <div className="form-check" key={material.id}>
              <label>
                <input
                  type="radio"
                  className="form-check-input"
                  onChange={() => setMaterialId(part.id, material.id)}
                  checked={material.id === part.materialId}
                />
                {material.name}
              </label>
            </div>
          ))
        }
      </div>
    </div>
    <div style={{marginLeft: '2em'}}>
      <h6 className="field-title">Description</h6>
      <p>{material.description}</p>
    </div>
  </div>
)

export default Materials;
