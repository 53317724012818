
export const getQuantityMultiplier = (quantity) => {
  if(quantity >= 100 && quantity < 250) {
    return 0.97;
  } else if(quantity >= 250 && quantity < 500) {
    return 0.94;
  } else if(quantity >= 500 && quantity < 750) {
    return 0.91;
  } else if(quantity >= 750 && quantity <= 1000) {
    return 0.88;
  } else if(quantity > 1000) {
    return 0.88; // something arbitrary - need to handle RFQ
  } else {
    return 1;
  }
}

const moldConstant = 10.0;
export const getMoldPrice = (basePrice) => {
  return (basePrice * moldConstant).toFixed(2);
}

export const perPartPrice = (basePrice, quantity) => {
}
