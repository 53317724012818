import Immutable from 'immutable'

export const MetaPart = Immutable.Record({
  id: null,
  dimensions: '',
  filename: '',
  partEnvelope: null,
  surfaceArea: null,
  partId: null,
  stlUrl: '',
  userId: null,
  area:null,
  volume: null,
  bulkVolume: null
});

export const MetaPartMap = Immutable.OrderedMap;
