import request from 'superagent'
import humps from 'humps'
import { normalize, arrayOf } from 'normalizr'
import * as actions from './actions'
import * as schema from './schema'
import { MAX_BULK_VOLUME } from '../constants'

// Arbitrary change to recompile
let host = process.env.HOST_URL
let requestFormat = process.env.REQUEST_FORMAT

const baseUrl = `${requestFormat}://${host}/api/v1`
const resinsUrl = baseUrl + '/resins'
const fabMethodsUrl = baseUrl + '/fabrication_methods'
const partUrl = baseUrl + '/parts'
const betajetPartUrl = `${baseUrl}/betajet_parts`
const quoteUrl = baseUrl + '/quotes'
const supplementalFileUrl = baseUrl + '/supplemental_files'
const shipmentUrl = baseUrl + '/shipments'
const orderUrl = baseUrl + '/orders'

/*************************
 * Async Action Creators *
 *************************/

export const fetchFabricationMethods = () => {
  return async dispatch => {
    dispatch(actions.requestFabricationMethods())

    const json = await request.get(fabMethodsUrl)

    const response = {
      fabricationMethods: humps.camelizeKeys(json.body),
    }

    let normalizedResponse = normalize(response, {
      fabricationMethods: arrayOf(schema.fabricationMethodSchema),
    })

    dispatch(actions.updateFabricationMethodEntities(normalizedResponse.entities.fabricationMethods))
    dispatch(actions.updateMaterialEntities(normalizedResponse.entities.materials))
    dispatch(actions.updateFinishEntities(normalizedResponse.entities.finishes))
  }
}

export const uploadPart = (quoteId, file, onError) => {
  return async dispatch => {
    dispatch(actions.beginPartUpload())
    dispatch(actions.setLoading(true))

    try {
      const json = await request
            .post(partUrl)
            .field('quote_id', quoteId)
            .attach('file', file)

      const part = { part: humps.camelizeKeys(json.body) }

      let normalizedPart = normalize(part, { part: schema.partSchema })

      const partId = normalizedPart.result.part
      const bulkVolume = normalizedPart.entities.metaParts[partId].bulkVolume
      if(bulkVolume > MAX_BULK_VOLUME) {
        throw new Error('part_too_large')
      }

      dispatch(actions.updatePartEntities(normalizedPart.entities.parts))
      dispatch(actions.updateMetaPartEntities(normalizedPart.entities.metaParts))
      dispatch(actions.receivePart(normalizedPart.result.part))
      dispatch(actions.setLoading(false))
    } catch(error) {
      console.error(error)
      dispatch(actions.setLoading(false))
      onError(error)
    }
  }
}

export const savePart = (part) => {
  const uploadPart = part.toJS()

  return async dispatch => {
    dispatch(actions.beginPartUpload())
    dispatch(actions.setLoading(true))

    try {
      const response = await fetch(`${betajetPartUrl}/${uploadPart.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(humps.decamelizeKeys(uploadPart))
      })
      const json = await response.json()

      let receivedPart = humps.camelizeKeys(json)
      dispatch(actions.setTotalPrice(receivedPart.id, receivedPart.basePrice));
      dispatch(actions.setPrice(receivedPart.id, receivedPart.price));
      dispatch(actions.setMoldPrice(receivedPart.id, receivedPart.moldPrice));
      dispatch(actions.setProductionPartPrice(receivedPart.id, receivedPart.productionPartPrice));
      dispatch(actions.setLoading(false))
    } catch(error) {
      console.log(error)
    }
  }
}

export const fetchQuote = (quoteId) => {
  return async dispatch => {
    try {
      const json = await request.get(`${quoteUrl}/${quoteId}`)
      const quote = { quote: humps.camelizeKeys(json.body) }

      let normalizedQuote = normalize(quote, { quote: schema.quoteSchema })
      dispatch(actions.updatePartEntities(normalizedQuote.entities.parts))
      dispatch(actions.updateMetaPartEntities(normalizedQuote.entities.metaParts))

      if(normalizedQuote.entities.supplementalFiles) {
        dispatch(actions.updateSupplementalFileEntities(normalizedQuote.entities.supplementalFiles))
      }

      Object.values(normalizedQuote.entities.parts).forEach(part => {
        dispatch(actions.setTotalPrice(part.id, part.basePrice))
      })

      dispatch(actions.receiveQuote(normalizedQuote.entities.quotes[quoteId]))
    } catch(error) {
      console.log(error)
    }
  }
}

export const createQuote = (userId = 0, file, onProgress, onError) => {
  return async dispatch => {
    dispatch(actions.beginPartUpload())

    try {
      const json = await request
            .post(quoteUrl)
            .field('user_id', userId)
            .attach('file', file)
            .on('progress', onProgress)

      const quote = { quote: humps.camelizeKeys(json.body) }

      let normalizedQuote = normalize(quote, { quote: schema.quoteSchema })
      const quoteId = normalizedQuote.result.quote;

      const bulkVolume = Object.values(normalizedQuote.entities.metaParts)[0].bulkVolume
      if(bulkVolume > MAX_BULK_VOLUME) {
        throw new Error('part_too_large')
      }

      dispatch(actions.updatePartEntities(normalizedQuote.entities.parts))
      dispatch(actions.updateMetaPartEntities(normalizedQuote.entities.metaParts))
      dispatch(actions.receiveQuote(normalizedQuote.entities.quotes[quoteId]))
      dispatch(actions.setActivePartId(normalizedQuote.entities.quotes[quoteId].parts[0]))
    } catch(error) {
      console.error(error)
      onError(error)
    }
  }
}

export const uploadSupplementalFile = (partId, file) => {
  return async dispatch => {
    dispatch(actions.setLoading(true))
    try {
      const json = await request
            .post(supplementalFileUrl)
            .field('part_id', partId)
            .attach('file', file);

      const supplementalFile = { supplementalFile: humps.camelizeKeys(json.body) }

      let normalizedFile = normalize(supplementalFile, { supplementalFile: schema.supplementalFileSchema })
      dispatch(actions.updateSupplementalFileEntities(normalizedFile.entities.supplementalFiles))
      dispatch(actions.setLoading(false))
    } catch(error) {
      console.log("Error", error)
    }
  }
}

export const deleteSupplementalFile = (fileId) => {
  return async dispatch => {
    dispatch(actions.setLoading(true))
    try {
      const json = await request
            .del(`${supplementalFileUrl}/${fileId}`)
            .set('Content-Type', 'application/json')

      dispatch(actions.removeSupplementalFile(fileId))
      dispatch(actions.setLoading(false))
    } catch(error) {
      console.log("Error", error)
    }
  }
}

export const deletePart = (partId) => {
  return async dispatch => {
    try {
      const json = await request
            .del(`${partUrl}/${partId}`)
            .set('Content-Type', 'application/json');
      dispatch(actions.removePart(partId));
    } catch(error) {
      console.log("Error", error)
    }
  }
}

/*************************
 * Standard API Requests *
 *************************/

export const createShipment = async (params, onSuccess, onError) => {
  const resp = await request
        .post(shipmentUrl)
        .send(params)
        .set('Content-Type', 'application/json')
        .on('error', onError)

  return onSuccess(resp.body)
}

export const createOrder = async (params, onSuccess, onError) => {
  const resp = await request
        .post(orderUrl)
        .send(params)
        .set('Content-Type', 'application/json')
        .on('error', onError)

  return onSuccess(resp.body)
}

