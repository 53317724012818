import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import cn from 'classnames'

class AddPartDropbox extends Component {
  state = {
    file: {},
    progress: 0
  }

  clearState = () => {
    this.setState({
      file: {},
      progress: 0
    })
  }

  handleDrop = (files) => {
    this.setState({
      file: files[0]
    })

    this.props.onDrop(
      this.props.quoteId,
      files[0],
      (error) => {
        this.clearState()
        this.props.onError(error.message)
      }
    )
    this.clearState()
  }

  render() {
    return (
      <Dropzone onDrop={this.handleDrop} multiple={false}>
        {({getRootProps, getInputProps, isDragActive}) => (
          <div {...getRootProps({
            className: cn("add-part-dropbox", {"add-part-dropbox--active": isDragActive})
          })}>
            <input {...getInputProps()}/>
            <h5 className="add-part-dropbox__text add-part-dropbox__text--betajet">Add Part To Quote <i className="fas fa-plus"></i></h5>
          </div>
        )}
      </Dropzone>
    )
  }
}

export default AddPartDropbox
