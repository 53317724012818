export class PartsHelper {
  constructor(store) {
    this.store = store
    this.activePart = this.getPart(store.activePartId)
  }

  getPart(partId) {
    return this.store.entities.parts.get(partId.toString())
  }
}

export class CartHelper {
  constructor(store) {
    this.store = store
  }

  getCartUrl() {
    let cartUrl = ''

    const productVariantList = this.store.entities.parts.map(part => {
      return part.shopifyVariantId + ':' + part.quantity
    })

    if(productVariantList.size > 0) {
      const productString = productVariantList.join()
      cartUrl = `https://betajet-store.myshopify.com/cart/${productString}`
    }

    return cartUrl
  }
}
