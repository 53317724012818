import React from 'react'
import Button from '../../Button'
import SupplementalFileDropbox from './SupplementalFileDropbox'

const SupplementalUploads = ({
  part,
  files,
  onDelete,
  onDrop
}) => (
  <div>
    <h6 className="field-title">Reference Files</h6>

    <div className="flex-horizontal">
      <SupplementalFileDropbox onDrop={onDrop} partId={part.id} />
      <span className="ml-4">pdf, jpeg, png</span>
    </div>

    <div className="mt-2">
      {
        files.valueSeq().map(file => {
          return (
            <div className="supplemental-file" key={file.id}>
              <span className="supplemental-file__filename">{file.filename}</span>
              <button
                onClick={() => onDelete(file.id)}
                className="supplemental-file__delete-button" >
                <i className="fas fa-times"></i>
              </button>
            </div>
          )
        })
      }
    </div>
  </div>
)

export default SupplementalUploads

