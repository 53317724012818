import React from 'react'

const cmConversion = (volume) => (
  Number(volume / 1000).toFixed(2)
)

const PartData = ({
  metaPart,
  material,
  finish, 
  color
}) => (
  <div>
    <h6 className="field-title">Part Data</h6>
    <table className="table table-sm table-borderless">
      <tbody >
        <tr>
          <th scope="row">Dimensions</th>
          <td>{metaPart.dimensions}</td>
        </tr>
        <tr>
          <th scope="row">Volume</th>
          <td>{cmConversion(metaPart.volume)}cm3</td>
        </tr>
        <tr>
          <th scope="row">Surface Area</th>
          <td>{cmConversion(metaPart.surfaceArea)}cm2</td>
        </tr>
        <tr>
          <th scope="row">Material</th>
          <td>{material.name}</td>
        </tr>
        <tr>
          <th scope="row">Finish</th>
          <td>{finish.name}</td>
        </tr>
        <tr>
          <th scope="row">Color</th>
          <td>{color}</td>
        </tr>
      </tbody>
    </table>
  </div>
)

export default PartData
