import React from 'react'
import cn from 'classnames'

const Spinner = ({hidden}) => (
  <div className={cn('spinner', {'invisible': hidden})}>
    <div className="double-bounce1 double-bounce1--betajet"></div>
    <div className="double-bounce2 double-bounce2--betajet"></div>
  </div>
)

export default Spinner
