import React, { useState, useEffect, useContext } from 'react'
import { MAX_ORDER_AMOUNT } from '../../constants'
import accounting from 'accounting'
import Button from './Button'
import AddPartDropbox from './AddPartDropbox'
import Spinner from './Spinner'
import UploadError from './notifications/UploadError'
import { UploadManagerContext, FilesContext } from '../../contexts'

const QuoteHeader = ({
  quote,
  totalPrice,
  cartUrl,
  uploadPart,
  loading
}) => {
  const [error, setError] = useState(null)
  const files = useContext(FilesContext)
  const { updateFiles } = useContext(UploadManagerContext)

  useEffect(() => {
    if(files.length > 0) {
      const nextFile = files.shift()
      uploadPart(quote.id, nextFile)
      updateFiles(files)
    }
  }, [quote, files, updateFiles])

  const handlePlaceOrder = () => {
    if(totalPrice === 0.0) {
      setError('material_not_selected')
    } else if(totalPrice > MAX_ORDER_AMOUNT) {
      setError('order_too_large')
    } else {
      window.location.href = `/quotes/${quote.id}/order`
    }
  }

  const clearError = () => setError(null);

  const renderError = () => {
    let message;
    switch(error) {
    case 'part_too_large':
      message = (
        <div>
          Looks like you are attempting to upload a large part.  For large parts, please email us directly at <a href="mailto:quotes@betajet.com?subject=Large%20Part%20manual%20RFQ&body=Please%20attach%20your%20part%20so%20that%20we%20can%20review%20it.">quotes@betajet.com</a> and we will be happy to assist.
        </div>
      )
      break;
    case 'material_not_selected':
      message = <div>Please ensure that you have selected a material for each of your parts</div>
      break;
    case 'order_too_large':
      message = (
        <div>
          For an order this large, please contact us directly at <a href="mailto:quotes@betajet.com?subject=Large%20Order%20manual%20RFQ&body=Please%20attach%20your%20parts%20so%20that%20we%20can%20review%20them.">quotes@betajet.com</a> and we will be happy to assist.
        </div>
      )
      break;
    default:
      message = <div>There was a problem with your order.</div>
    }

    return (
      <UploadError onClose={clearError}>
        {message}
      </UploadError>
    )
  }

  return (
    <div>
      { error && renderError() }
      <div className="quote-header">
        <h6 className="field-title field-title--no-margin">
          Quote: <span className="quote-header__price">{quote.id}</span>
        </h6>
        <div className="quote__add-part">
          <div className="loading-indicator">
            <Spinner hidden={!loading} />
          </div>
          <AddPartDropbox
            onDrop={uploadPart}
            quoteId={quote.id}
            onError={setError}
          />
        </div>
        <div className="flex-horizontal">
          <h6 className="field-title field-title--no-margin">
            Total: <span className="quote-header__price">{accounting.formatMoney(totalPrice)}</span>
          </h6>
          <Button text="Continue to Shipping" type="success" onClick={handlePlaceOrder}/>
        </div>
      </div>
    </div>
  )
}

QuoteHeader.defaultProps = {
  loading: true
}

export default QuoteHeader
