import React from 'react'
import { connect } from 'react-redux'
import { createQuote } from '../../actions/requests'
import Dropbox from '../common/Dropbox'
import UploadError from '../common/notifications/UploadError'

class Upload extends React.Component {
  state = {
    error: null
  }

  clearError = () => {
    this.setState({
      error: null
    })
  }

  setError = (error) => {
    this.setState({
      error: error
    })
  }

  renderError = () => {
    let message;
    switch(this.state.error) {
      case 'part_too_large':
        message = (
          <div>
            Looks like you are attempting to upload a large part.  For large parts, please email us directly at <a href="mailto:quotes@betajet.com?subject=Large%20Part%20manual%20RFQ&body=Please%20attach%20your%20part%20so%20that%20we%20can%20review%20it.">quotes@betajet.com</a> and we will be happy to assist.
          </div>
        )
        break;
      case 'too_many_files':
        message = (
          <div>
            Too many files. You may only upload 10 files at once.
          </div>
        )
        break;
      default:
        message = <div>There was a problem uploading your file.  Please ensure that it is a STEP file.  If you still have trouble, please contact us using the link below.</div>
    }

    return (
      <UploadError onClose={this.clearError}>
        {message}
      </UploadError>
    )
  }

  render() {
    return (
      <div className="upload-view">
        <h1 className="upload-view__heading">Upload CAD File</h1>
        <h4 className="upload-view__subheading">STEP only</h4>
        { this.state.error && this.renderError() }
        <Dropbox
          onDrop={this.props.createQuote}
          userId={this.props.user.id}
          onError={this.setError}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const user = state.user.toJS()

  return {
    user
  }
}

const mapDispatchToProps = (dispatch) => ({
  createQuote: (userId, file, onProgress, onError) => dispatch(createQuote(userId, file, onProgress, onError))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Upload)
