import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import App from '../betajetQuoteEngine/App'

document.addEventListener('DOMContentLoaded', () => {
  const ele = document.getElementById('quote-engine-component')
  const user = ele.dataset.user
  const quoteId = ele.dataset.quoteId

  ReactDOM.render(
    <App user={user} quoteId={quoteId} />,
    ele
  )
})
