// General
export const SET_LOADING = 'SET_LOADING'

// Active Part ID
export const SET_ACTIVE_PART_ID = 'SET_ACTIVE_PART_ID'

// Quote
export const RECEIVE_QUOTE = 'RECEIVE_QUOTE'
export const SET_QUOTE_ID = 'SET_QUOTE_ID'

// User
export const SET_USER = 'SET_USER'

// Fabrication Methods
export const REQUEST_FABRICATION_METHODS = 'REQUEST_FABRICATION_METHODS'
export const UPDATE_FABRICATION_METHOD_ENTITIES = 'UPDATE_FABRICATION_METHOD_ENTITIES'

// Materials
export const UPDATE_MATERIAL_ENTITIES = 'UPDATE_MATERIAL_ENTITIES'

// Finishes
export const UPDATE_FINISH_ENTITIES = 'UPDATE_FINISH_ENTITIES'

// Part
export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'

export const BEGIN_PART_UPLOAD = 'BEGIN_PART_UPLOAD'
export const UPDATE_PART_ENTITIES = 'UPDATE_PART_ENTITIES'
export const RECEIVE_PART = 'RECEIVE_PART'

export const UPDATE_META_PART_ENTITIES = 'UPDATE_META_PART_ENTITIES'

export const UPDATE_SUPPLEMENTAL_FILE_ENTITIES = 'UPDATE_SUPPLEMENTAL_FILE_ENTITIES'
export const REMOVE_SUPP_FILE = 'REMOVE_SUPP_FILE'

export const REMOVE_PART = 'REMOVE_PART'
export const SET_PRICE = 'SET_PRICE'
export const SET_TOTAL_PRICE = 'SET_TOTAL_PRICE'
export const SET_MOLD_PRICE = 'SET_MOLD_PRICE'
export const SET_PRODUCTION_PART_PRICE = 'SET_PRODUCTION_PART_PRICE'
export const SET_FAB_METHOD = 'SET_FAB_METHOD'
export const SET_MATERIAL = 'SET_MATERIAL'
export const SET_QUANTITY = 'SET_QUANTITY'
export const SET_PROD_QUANTITY = 'SET_PROD_QUANTITY'
export const SET_NAME = 'SET_NAME'
export const SET_COLOR = 'SET_COLOR'
export const SET_FINISH = 'SET_FINISH'
export const SET_FINISH_NOTES = 'SET_FINISH_NOTES'
export const SET_GENERAL_NOTES = 'SET_GENERAL_NOTES'
export const SET_PART_VIEW = 'SET_PART_VIEW'
export const SET_COLOR_CODE = 'SET_COLOR_CODE'
export const SET_TEXTURE_CODE = 'SET_TEXTURE_CODE'

// General Non-Redux related constants
export const MAX_BULK_VOLUME = 1500
export const MAX_PROD_QUANTITY = 1000
export const MAX_ORDER_AMOUNT = 100000
