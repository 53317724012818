import React from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'

import {
  setColor,
  setFinish,
  setColorCode,
  setMaterialId,
  setGeneralNotes,
  setActivePartId,
  setQuantity,
  setProdQuantity,
  clearNotification,
  setNotification
} from '../../actions/actions'

import {
  savePart,
  deletePart,
  uploadSupplementalFile,
  deleteSupplementalFile
} from '../../actions/requests'

import PartBody from '../common/parts/body/PartBody'
import PartFooter from '../common/parts/PartFooter'
import PartInfo from '../common/parts/header/PartInfo'
import PartPricing from '../common/parts/header/PartPricing'
import ErrorMessage from '../common/notifications/ErrorMessage'
import Button from '../common/Button'

const watchedAttrs = [
  'color', 'finish', 'generalNotes', 'materialId',
  'quantity', 'prodQuantity','colorCode'
]

const partShouldUpdate = (part, prevPart) => {
  return watchedAttrs.some(attr => part[attr] !== prevPart[attr])
}

class Part extends React.Component {
  componentDidUpdate(prevProps) {
    if(partShouldUpdate(this.props.part, prevProps.part)) {
      this.debouncedSave();
    }
  }

  debouncedSave = debounce(() => {
    this.props.savePart(this.props.part)
  }, 500)

  handleSave = () => {
    this.props.savePart(this.props.part)
  }

  render() {
    const {
      part,
      metaPart,
      material,
      finish,
      supplementalFiles,
      fabricationMethods,
      finishes,
      materials,
      setColor,
      setFinish,
      setColorCode,
      setMaterialId,
      setGeneralNotes,
      setActivePartId,
      setQuantity,
      setProdQuantity,
      clearNotification,
      setNotification,
      uploadSupplementalFile,
      deleteSupplementalFile,
      savePart,
      deletePart,
      active,
    } = this.props

    return (
      <div className="part">
        <div className="part-header">
          <div className="part-header-top">
            <h5 className="part-header-top__part-title">{metaPart.filename}</h5>
            <div className="part-header-top__right">
              <h6 className="field-title">Part #: <span className="part-header-top__part-id"> {part.id}</span></h6>
              <Button
                text={<i className="fas fa-trash"></i>}
                type="plain"
                onClick={() => {
                  const confirmation = confirm("Are you sure you want to remove this part from your order?");
                  if(confirmation) {
                    deletePart(part.id);
                  }
                }}
              />
              {
                active
                  ? <Button
                      key={1}
                      text={<i className="fas fa-chevron-up"></i>}
                      onClick={() => setActivePartId(0)}
                      type="plain"
                      style={{color: '#3FA9F5', fontSize: '1.5em', padding: '0.25em'}}
                    />
                  : <Button
                      key={2}
                      text={<i className="fas fa-chevron-down"></i>}
                      onClick={() => setActivePartId(part.id)}
                      type="plain"
                      style={{color: '#3FA9F5', fontSize: '1.5em', padding: '0.25em'}}
                    />
              }
            </div>
          </div>
        </div>
        {
          part.notification !== '' && (
            <div className="mb-2">
              <ErrorMessage
                message={part.notification}
                onClose={() => clearNotification(part.id)}
              />
            </div>
          )
        }
        <div className="part-content">
          <div className="part-content__left">
            <PartInfo
              part={part}
              metaPart={metaPart}
              material={material}
              finish={finish}
            />

            {
              active && (
                <div>
                  <PartBody
                    part={part}
                    fabricationMethods={fabricationMethods}
                    materials={materials}
                    material={material}
                    finishes={finishes}
                    finish={finish}
                    setColor={setColor}
                    setFinish={setFinish}
                    setColorCode={setColorCode}
                    setMaterialId={setMaterialId}
                    setNotification={setNotification}
                    clearNotification={() => clearNotification(part.id)}
                    supplementalFiles={supplementalFiles}
                    setGeneralNotes={setGeneralNotes}
                    setProdQuantity={setProdQuantity}
                    uploadSupplementalFile={uploadSupplementalFile}
                    deleteSupplementalFile={deleteSupplementalFile}
                  />
                </div>
              )
            }
          </div>
          <div className="part-content__right">
            <PartPricing
              key={part.id}
              part={part}
              onQuantityChange={setQuantity}
              onProdQuantityChange={setProdQuantity}
              setNotification={setNotification}
              active={active}
            />
          </div>
        </div>
        { active && (
          <div className="mt-4">
            <PartFooter onCancel={() => setActivePartId(0)} onSave={this.handleSave} />
          </div>
          )
        }
      </div>
    )
  }
}

// Let the part component denormalize its own data from the
// redux state.
const mapStateToProps = (state, ownProps) => {
  const part = ownProps.part
  const user = state.user.toJS()
  const metaPart = state.entities.metaParts.filter(mp => mp.partId == part.id).first()
  const material = part.materialId !== null && state.entities.materials.get(part.materialId.toString())
  const finishes = material && material.finishes.map(finish => state.entities.finishes.get(finish.toString()))
  const finish = part.finish !== 0 && state.entities.finishes.get(part.finish.toString())
  const supplementalFiles = state.entities.supplementalFiles.filter( f => f.partId === part.id )

  const fabricationMethods = state.entities.fabricationMethods
  const materials = state.entities.materials

  const active = part.id === state.activePartId

  return {
    user,
    part,
    metaPart,
    material,
    finish,
    supplementalFiles,
    fabricationMethods,
    materials,
    finishes,
    active
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setColor: (partId, color) => dispatch(setColor(partId, color)),
    setFinish: (partId, finish) => dispatch(setFinish(partId, finish)),
    setColorCode: (partId, colorCode) => dispatch(setColorCode(partId, colorCode)),
    setMaterialId: (id, mId) => dispatch(setMaterialId(id, mId)),
    setGeneralNotes: (id, notes) => dispatch(setGeneralNotes(id, notes)),
    setActivePartId: (partId) => dispatch(setActivePartId(partId)),
    setQuantity: (id, quantity) => dispatch(setQuantity(id, quantity)),
    setProdQuantity: (id, quantity) => dispatch(setProdQuantity(id, quantity)),
    clearNotification: (partId) => dispatch(clearNotification(partId)),
    setNotification: (id, notification) => dispatch(setNotification(id, notification)),
    deleteSupplementalFile: (fileId) => dispatch(deleteSupplementalFile(fileId)),
    uploadSupplementalFile: (partId, file) => dispatch(uploadSupplementalFile(partId, file)),
    savePart: (part) => dispatch(savePart(part)),
    deletePart: (id) => dispatch(deletePart(id)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Part)
