import React, { useState } from 'react'
import cn from 'classnames'
import Materials from './Materials'
import Textures from './Textures'
import Colors from './Colors'
import Notes from './Notes'

const PartBody = ({
  materials,
  material,
  part,
  finishes,
  finish,
  setColor,
  setFinish,
  setColorCode,
  setMaterialId,
  setNotification,
  clearNotification,
  supplementalFiles,
  setGeneralNotes,
  uploadSupplementalFile,
  deleteSupplementalFile
}) => {
  const [activeTab, setActiveTab] = useState('materials');

  const handleTabChange = (newTab) => {
    if(part.materialId === null && newTab !== "materials") {
      setNotification(part.id, "Please select a material.");
    } else {
      clearNotification();
      setActiveTab(newTab);
    }
  }

  const renderTab = () => {
    switch(activeTab) {
    case 'materials':
      return <Materials
               materials={materials}
               part={part}
               material={material}
               setMaterialId={setMaterialId}
             />;
    case 'textures':
      return <Textures
               part={part}
               finishes={finishes}
               finish={finish}
               setFinish={setFinish}
             />;
    case 'colors':
      return <Colors
               part={part}
               setColor={setColor}
               setColorCode={setColorCode}
             />;
    case 'notes':
      return <Notes
               part={part}
               supplementalFiles={supplementalFiles}
               setGeneralNotes={setGeneralNotes}
               uploadSupplementalFile={uploadSupplementalFile}
               deleteSupplementalFile={deleteSupplementalFile}
             />
    }
  }

  return (
    <div className="part-body">
      <ul className="nav nav-tabs part-body__nav">
        <li className={cn("nav-item", {active: activeTab === 'materials'})}>
          <a  onClick={() => handleTabChange("materials")}
              className={cn("nav-link", {active: activeTab === 'materials'})}>Material</a>
        </li>
        <li className={cn("nav-item", {active: activeTab === "textures"})}>
          <a  onClick={() => handleTabChange("textures")}
              className={cn("nav-link", {active: activeTab === 'textures'})}>Texture</a>
        </li>
        <li className={cn("nav-item", {active: activeTab === "colors"})}>
          <a  onClick={() => handleTabChange("colors")}
              className={cn("nav-link", {active: activeTab === 'colors'})}>Color</a>
        </li>
        <li className={cn("nav-item", {active: activeTab === "notes"})}>
          <a  onClick={() => handleTabChange("notes")}
              className={cn("nav-link", {active: activeTab === 'notes'})}>Notes</a>
        </li>
      </ul>
      <div className="part-body__content">
        { renderTab() }
      </div>
    </div>
  )
}


export default PartBody;
