import React, { useState } from 'react'

const colors = [
  'Black',
  'White',
  'Clear',
  'Custom'
]

const Colors = ({ part, ...props}) => {
  const color = part.color;
  const [colorCode, setColorCode] = useState(part.colorCode);

  return (
    <div className="colors-container" >
      <div>
        <h6 className="field-title">Color</h6>
        {
          colors.map((option, index) => (
            <div className="form-check" key={index} >
              <label >
                <input
                  type="radio"
                  className="form-check-input"
                  onChange={(e) => { props.setColor(part.id, option); setColorCode(''); }}
                  checked={option === color}
                  value={option}
                />
                {option}
              </label>
            </div>
          ))
        }
      </div>
      {
        color === 'Custom' && (
          <div style={{flexBasis: '25%', marginLeft: '3em'}}>
            <label>
              Pantone Color
              <input
                type="text"
                className="form-control"
                placeholder="Pantone #"
                onChange={(e) => setColorCode(e.target.value)}
                value={colorCode}
                onBlur={() => props.setColorCode(part.id, colorCode)}
              />
            </label>
          </div>
        )
      }
    </div>
  )
}

export default Colors;
